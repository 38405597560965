

















































import { Vue, Component, Prop } from 'vue-property-decorator';
import { MeetingParticipant } from '@/classes/MeetingParticipant.resource';
@Component({})
export default class ResponsibleSelect extends Vue {
	@Prop({ default: [] })
	participants: MeetingParticipant[];

	@Prop({ default: [] })
	selectedParticipants: MeetingParticipant[];

	public displayList = false;

	async addOrRemoveParticipant(part: MeetingParticipant): Promise<void> {
		if (this.selectedParticipantsIds.includes(Number(part.id))) {
			this.$emit('remove', part);
		} else {
			this.$emit('add', part);
		}
	}

	async openPanel(): Promise<void> {
		if (this.displayList === false) {
			this.displayList = true;
			setTimeout(() => {
				const list = document.getElementById('participant-list');
				if (list) {
					list.focus();
				}
			}, 1);
		} else if (this.displayList === true) {
			this.displayList = false;
		}
	}

	async closePanel(): Promise<void> {
		setTimeout(() => {
			this.displayList = false;
		}, 110);
	}

	isSelected(partId: number): boolean {
		return this.selectedParticipantsIds.includes(partId);
	}

	get selectedParticipantsIds(): number[] {
		const ids: number[] = [];
		if (this.selectedParticipants && this.selectedParticipants.length > 0) {
			this.selectedParticipants.forEach((part) => {
				ids.push(Number(part.id));
			});
		}
		return ids;
	}
}
